$COL1: #f2f2f2; //
$COLFLAT1: #f2f2f2; //
$COL2: #75bb03; //
$COLFLAT2: #75bb03; //
$COL3: #ff822e; //
$COLFLAT3: #ff822e; //
$COL4: #eeeeee; //
$COLFLAT4: #eeeeee; //
$COL5: #ffffff; //
$COLFLAT5: #ffffff; //
$COL6: #e8e8e8; //
$COLFLAT6: #e8e8e8; //
$COL7: #292c30; //
$COLFLAT7: #292c30; //
$COL8: #75bb03; //
$COLFLAT8: #75bb03; //
$COL9: #ffffff; //
$COLFLAT9: #ffffff; //
$COL10: #ffffff; //
$COLFLAT10: #ffffff; //
$COL11: #2b2e34; //
$COLFLAT11: #2b2e34; //
$COL12: #2b2e34; //
$COLFLAT12: #2b2e34; //
$COL13: #2b2e34; //
$COLFLAT13: #2b2e34; //
$COL14: #2b2e34; //
$COLFLAT14: #2b2e34; //
$COL15: #2b2e34; //
$COLFLAT15: #2b2e34; //
$COL16: #2b2e34; //
$COLFLAT16: #2b2e34; //
$COL17: #2b2e34; //
$COLFLAT17: #2b2e34; //
$COL18: #2b2e34; //
$COLFLAT18: #2b2e34; //
$COL19: #fefefe; //
$COLFLAT19: #fefefe; //
$COL20: #ffffff; //white
$COLFLAT20: #ffffff; //white
$COL21: #2a2d31; //Menu Background
$COLFLAT21: #2a2d31; //Menu Background
$COL22: #a68153; //Gold
$COLFLAT22: #a68153; //Gold
$COL23: #73777e; //Footer Background
$COLFLAT23: #73777e; //Footer Background
$COL24: #2b2e34; //
$COLFLAT24: #2b2e34; //
$COL25: #ff822e; //
$COLFLAT25: #ff822e; //
$COL26: #e0e0e0; //Light Grey
$COLFLAT26: #e0e0e0; //Light Grey
$COL27: rgba(0,0,0,0); //
$COLFLAT27: #808080; //
$COL28: #bbbbbb; //grey
$COLFLAT28: #bbbbbb; //grey
$COL29: rgba(0,0,0,0.4); //black 40%
$COLFLAT29: #4d4d4d; //black 40%
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
